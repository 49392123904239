import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CjSliderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cj-slider-wrap': {
    '.MuiSlider-root': {
      '&.Mui-disabled': {
        color: theme.palette.grey[100],
        pointerEvents: 'auto',
        '.MuiSlider-thumb': {
          '&::after': {
            backgroundColor: theme.palette.grey[100]
          },
          '&:hover': {
            '.MuiSlider-valueLabel': {
              transform: 'translateY(-100%) scale(1)'
            }
          }
        }
      }
    },
    '.MuiSlider-thumb': {
        width: 24,
        height: 24,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
        '&::before': {
          width: 8,
          height: 8,
          boxShadow: 'none',
          backgroundColor: theme.palette.common.white,
          zIndex: 1
        },
        '&::after': {
          width: 24,
          height: 24,
          backgroundColor: theme.palette.primary.main
        },
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16) !important'
        },
      },
      '.MuiSlider-valueLabel': {
        borderRadius: 34,
        boxShadow: '0px 16px 16px -8px #00000029',
        padding: '8px 16px',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[900],
        border: '1px solid' + theme.palette.grey[50],
        textAlign: 'center',
        '.MuiSlider-valueLabelLabel': {
            position: 'relative',
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            fontWeight: theme.typography.fontWeightBold,
            '&::after': {
                display: 'block',
                content: '" "',
                fontSize: 10,
                lineHeight: '12px',
                color: theme.palette.grey[600],
                fontWeight: theme.typography.fontWeightRegular
            }
        }
    }
  }
}))
