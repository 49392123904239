import React, { useContext, useEffect, useReducer, useState } from 'react'
import { ForgotPasswordComponent } from '@ntpkunity/controls-ums'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Snackbar } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'

import { SnackbarUtility } from 'utilities'
import { Navigation } from '../../../constants'
import { AuthLayoutWrap } from '../layout.style'
import { AppContext } from '@app/context-provider'
import { useWorkflowContext } from '@app/router-workflow'

export const ForgotPasswordPage = () => {
  const theme = useTheme()
  const { tenant } = useParams()
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
  const [response, setResponse] = useState<any>({})
  // Get email from URL query string
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')

  const { navigateNext } = useWorkflowContext()

  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  useEffect(() => {
    if (response?.status === 200) {
      navigateNext(`${Navigation.AUTHENTICATION.RESEND_PASSWORD_LINK.replace(':tenant', tenant)}?email=${email}`)
    } else if (response?.status === 404) {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.FORGOT_PASSWORD_INPUT_REGISTERED_EMAIL_ID))
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
    }
  }, [response])

  const translations = {
    title: PLACEHOLDERS.FORGOT_PASSWORD_TITLE,
    emailFieldLabel: PLACEHOLDERS.FORGOT_PASSWORD_EMAIL_FIELD_LABEL,
    helpText: PLACEHOLDERS.FORGOT_PASSWORD_HELP_TEXT,
    buttonText: PLACEHOLDERS.FORGOT_PASSWORD_BUTTON_TEXT,
    seoButtonText: PLACEHOLDERS.FORGOT_PASSWORD_SEO_BUTTON_TEXT,
    emailAddressText: PLACEHOLDERS.EMAIL_ADDRESS_TEXT,
    isRequiredText: PLACEHOLDERS.IS_REQUIRED_TEXT,
    validEmailRequiredText: PLACEHOLDERS.VALID_EMAIL_IS_REQUIRED
  }

  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      <ForgotPasswordComponent
        email={email}
        theme={theme}
        setResponse={setResponse}
        nextScreenUrl={`${Navigation.AUTHENTICATION.RESET_PASSWORD.replace(':tenant', tenant)}`}
        layoutText={translations}
        slug={tenant}
      />
      <Snackbar
        theme={theme}
        message={snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
    </AuthLayoutWrap>
  )
}
