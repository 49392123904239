import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { PageWrap } from './trade-in.style'
import { PageHeader } from '@components'
import { ProductDetailCard, QuestionStack } from '@libraries'
import {convertToAmount, convertToAmountWithDecimals, mapTradeInType, scrollOnTop} from "../../../helpers/methods";
import { TradeInPopup } from "./trade-in.popup";
import { Skeleton } from "@ntpkunity/controls";
import { updateOrderTradeIn, useOrderContext } from 'pages/(order-management)/order/order-context-provider'
import { EstimateProvider, Integration_type, TradeInJourneySteps } from "../../../constants/enums";
import { useAppContext } from '@app/context-provider'
import {AlertDialog} from '../../../libraries/trade-in-journey-components/alert-popup/alert-popup'
import { Image } from '@ntpkunity/controls'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType } from 'constants/enums';
import { LeadKey, QueryKeys, user_session } from '@constants'
import { useParams } from 'react-router-dom'
import {useUpdateLead} from 'hooks'
import { mapPayload } from 'helpers/lead'
import { useQueryClient } from 'react-query'
import {LenderIntegrationType} from "../../../constants/providers";
import {useGetConfiguration} from "../../../hooks/trade-in-management";


export const TradeInPage = () => {
  const theme = useTheme()
  const { state: { order }, dispatch: orderDispatch } = useOrderContext()
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders
  const { dealer_id, order_asset: {make, model} } = order

  const [initialPopupType, setInitialPopupType] = useState(TradeInJourneySteps.AddTradeInPopup)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [payOffColor, setPayOffColor] = useState("")
  const [estimatedAmount, setEstimatedAmount] = useState('')
  const [resetForm, setResetForm] = useState(false)
  const { mutate: updateLead } = useUpdateLead(appState?.slug);
  const queryClient = useQueryClient();
  const customer:any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL)

  const dealer = JSON.parse(localStorage.getItem('dealer_information'))?.dealer
  const { data: kbbConfiguration, isLoading: checkingKbbConfiguration } = useGetConfiguration(dealer?.dealer_code,LenderIntegrationType.TRADE_IN_VALUATION,EstimateProvider.KBB, appState?.tenant, appState?.slug)
  const [TradeInSummaryDetails, setTradeInSummaryDetails] = useState(order?.order_tradein)
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: '', subtitle: '', btnText: '' });
  const { tenant } = useParams()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags();

  useEffect(() => {
    const page_event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.ADDONS_END,
      user_id: user_profile?.user_id,
      page_type:PageType.Addons
      }
      createTags(page_event)
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.DISPLAY_TRADE_IN,
      dealer_id: +dealer_id,
      vehicle_make: make?? '',
      vehicle_model: model?? '',
      user_id: user_profile?.user_id,
      page_type:PageType.TradeIn
      }
      createTags(event)
  }, [])
  const trade_in_value= (val:any)=>{
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:val,
      user_id: user_profile?.user_id,
      dealer_id: +dealer_id,
      vehicle_make: make,
      vehicle_model: model,
      page_type:PageType.TradeIn
      }
      createTags(event)
  }
  useEffect(() => {
    setTradeInSummaryDetails(order?.order_tradein)
    const tradIn = order?.order_tradein
    let tradeInAmount = tradIn?.trade_in_amount ? tradIn?.trade_in_amount : 0

    tradeInAmount = typeof tradeInAmount === 'number' ? tradeInAmount : parseInt(tradeInAmount);
    let payoffAmount = order?.order_tradein?.payoff_amount ? order?.order_tradein?.payoff_amount : 0

    payoffAmount = typeof payoffAmount === 'number' ? payoffAmount : parseInt(payoffAmount);

    const equity = tradeInAmount - payoffAmount

    const AdjustedAmount = convertToAmountWithDecimals(tradeInAmount, appState?.default_currency_code, 0, 0)
    setEstimatedAmount(AdjustedAmount)

    const equityLabel = equity >= 0 ? "" : "danger"
    setPayOffColor(equityLabel)

  }, [order]);
  useEffect(()=>{
    if(order?.order_tradein && customer){
      let lead = mapPayload(+dealer_id,tenant,customer?.reference_id,LeadKey.Vehicle_Detail,order?.order_tradein,LeadKey.Trade_In)
      updateLead(lead);
    }
  },[])

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };


  useEffect(() => {

    let basePopupType = order?.order_tradein?.trade_in_type? mapTradeInType(order.order_tradein.trade_in_type) : TradeInJourneySteps.AddTradeInPopup;

    if (!kbbConfiguration?.is_active) {
      basePopupType = TradeInJourneySteps.ProvideManualEstimatePopup;
    }

    setInitialPopupType(basePopupType);
  }, [kbbConfiguration, order?.order_tradein?.trade_in_type]);

  const handlePopupOpen = () => {

    setResetForm(false)
    openPopup()
  }

  const handleRemove = () => {
    setInitialPopupType(initialPopupType)
    setResetForm(true)
    orderDispatch(updateOrderTradeIn(null))
    setIsPopupOpen(false)

  }


  const handleAlertOpen = () => {
    setAlertContent({
      title: PLACEHOLDERS.TRADE_IN_REMOVAL_WARNING,
      subtitle: PLACEHOLDERS.TRADE_IN_REMOVAL_CONFIRMATION,
      btnText: PLACEHOLDERS.TRADE_IN_REMOVAL_ACTION
    });
    setIsAlertOpen(true);
  };

  const handleAlertClose = () => {
    setIsAlertOpen(false);
  };

  const confirmRemove = () => {
    handleRemove();
    handleAlertClose();
  };


  return (
    <PageWrap theme={theme} className='page-wrap'>
      <PageHeader
        marginBottom={3}
        title={`${TradeInSummaryDetails && estimatedAmount ? `${PLACEHOLDERS.TRADE_IN_ESTIMATE_TEXT} ${estimatedAmount}` : PLACEHOLDERS.TRADE_IN_PROMPT_GET_ESTIMATE}`}
        subTitle={`${TradeInSummaryDetails ? `${PLACEHOLDERS.TRADE_IN_DISCLAIMER_INSPECTION}\n` +
          PLACEHOLDERS.TRADE_IN_DISCLAIMER_MARKET_VALUE : PLACEHOLDERS.TRADE_IN_BENEFITS_TEXT}`}
      />
      {!TradeInSummaryDetails && (

        <QuestionStack
          dFlex
          title={<>{checkingKbbConfiguration ? <Skeleton theme={theme} width={200} height={24} /> : PLACEHOLDERS.TRADE_IN_YES_OPTION}</>}
          actionArea
          onLinkClick={() => {
            checkingKbbConfiguration ? false : handlePopupOpen()
            trade_in_value(PLACEHOLDERS.TRADE_IN_YES_OPTION)
          }}
        />
      )}

      {TradeInSummaryDetails && (

          <ProductDetailCard
            title={TradeInSummaryDetails.model && TradeInSummaryDetails.model.split(',').length >= 2 ? TradeInSummaryDetails.model.trim().replace(',', ' ').replace(',', ' ') : `${TradeInSummaryDetails?.year} ${TradeInSummaryDetails?.make} ${TradeInSummaryDetails?.model}  ${TradeInSummaryDetails?.trim ? TradeInSummaryDetails?.trim : ''}`}

            subTtile={estimatedAmount}
            listItems={[
              {
                title: PLACEHOLDERS.TRADE_IN_OFFER_LABEL,
                value: '-'
              },
              {
                title: PLACEHOLDERS.TRADE_IN_PAYOFF_AMOUNT_LABEL,
                value: `${convertToAmountWithDecimals((TradeInSummaryDetails?.payoff_amount ? TradeInSummaryDetails?.payoff_amount : 0), appState?.default_currency_code, 0, 0)}`
              },
              {
                title: PLACEHOLDERS.TRADE_IN_EQUITY_LABEL,
                value: `${convertToAmountWithDecimals((TradeInSummaryDetails?.trade_in_amount ? TradeInSummaryDetails?.trade_in_amount : 0) - (TradeInSummaryDetails?.payoff_amount ? TradeInSummaryDetails?.payoff_amount : 0), appState?.default_currency_code, 0, 0)}`,
                textType: payOffColor
              },
              {
                title: PLACEHOLDERS.TRADE_IN_ESTIMATED_MILEAGE_LABEL,
                value: `${(TradeInSummaryDetails?.mileage ? convertToAmount(TradeInSummaryDetails?.mileage) : 0)} ${appState.mileage_unit}`
              },

              {
                title: PLACEHOLDERS.TRADE_IN_CONDITION_LABEL,
                value: TradeInSummaryDetails?.condition
              }
            ]}
            onBtnClick={() => {
              openPopup()
            }}
            buttonText={PLACEHOLDERS.TRADE_IN_EDIT_DETAILS_BUTTON}
            onLinkClick={() => handleAlertOpen()}
            linkText={PLACEHOLDERS.TRADE_IN_REMOVE_BUTTON}
            mediaContent={
              <Image
                theme={theme}
                src={TradeInSummaryDetails?.asset_image?.length > 0 ? TradeInSummaryDetails.asset_image[0] : null}
                aspectRatio='16:9'
                alt='summary detail image'
              />
            }
          />

      )}

      <TradeInPopup isPopupOpen={isPopupOpen} closePopup={closePopup} initialPopupType={initialPopupType} setInitialPopupType={setInitialPopupType} resetForm={resetForm} handleRemove={handleRemove}  />

      {isAlertOpen &&
        <AlertDialog
          title={alertContent.title}
          subtitle={alertContent.subtitle}
          btnText={alertContent.btnText}
          isOpen={isAlertOpen}
          onClick={confirmRemove}
          onClose={handleAlertClose}
        />}

    </PageWrap>

  )
}