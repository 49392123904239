import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { ProfileLayoutWrap } from "./profile-layout.style";
import {Box, Icon, List, Skeleton, Typography} from "@ntpkunity/controls";
import { GradiantText } from "@components";
import {
  AppointmentsPage,
  FavoritesPage,
  MyOrdersPage,
  PrefrencesPage,
  PreQualificationPage,
  ProfilePage,
} from "pages";
import { profileTabs, QueryKeys } from "@constants";
import { useAppContext } from "@app/context-provider";
import { useQuery, useQueryClient } from "react-query";
import {useGetCustomerByEmail, useGetCustomerContact, useGetLicenseInformation} from "@hooks";

export const ProfileLayout = () => {
  const theme = useTheme();
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders;
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const customerData: any = useQuery(QueryKeys.GET_CUSTOMER_BY_EMAIL, user_profile?.email);
  const [activeTab, setActiveTab] = useState<string>(profileTabs.PROFILE);
  const customer_data = JSON.parse(localStorage.getItem("user_profile"))
  const [customerName, setCustomerName] = useState<string>('');
  const [customerZipcode, setCustomerZipCode] = useState();

  useEffect(() => {
    if (customerData?.data?.first_name) {
      const fullName = `${customerData?.data?.first_name} ${customerData?.data?.last_name}`;
      const zipCodeData = localStorage.getItem('user_profile')
      const zipCodeObject = JSON.parse(zipCodeData);
      setCustomerZipCode(zipCodeObject.zip_code);
      setCustomerName(fullName.trim());
    }
  }, [customerData]);
  const getTab = (tab: string) => {
    switch (tab) {
      case profileTabs.PROFILE:
        return <ProfilePage />;
      case profileTabs.ORDERS:
        return <MyOrdersPage />;
      case profileTabs.FAVORITES:
        return <FavoritesPage />
      case profileTabs.PREFERENCES:
        return <PrefrencesPage />;
      case profileTabs.APPOINTMENTS:
        return <AppointmentsPage />
      case profileTabs.PRE_QUALIFIED:
        return <PreQualificationPage />;
      default:
        return <></>;
    }
  };
  return (
    <ProfileLayoutWrap theme={theme} className="profile-layout-wrap">
      <Box theme={theme} className="aside">
        <Box theme={theme} className="welcome-area">
          <GradiantText bgText={PLACEHOLDERS.HELLO_TEXT} />
          <Box theme={theme} className="text-area">
            <Typography
              theme={theme}
              className="text-muted fw-medium"
              variant="body1"
              component={"p"}
            >
              {PLACEHOLDERS.PROFILE_LAYOUT_WELCOME_BACK}
            </Typography>
            <Typography theme={theme} variant="h3" component={"h3"}>
              {!customerName ?
              <Skeleton theme={theme} width={'100%'} height={'40px'}/> : customerName }
            </Typography>
            <Typography
              theme={theme}
              className="text-muted fw-medium"
              variant="caption"
              component={"small"}
              display={"block"}
              mt={1}
            >
              {PLACEHOLDERS.PROFILE_LAYOUT_YOUR_ZIP_CODE}: <span className="text-primary">{customerZipcode}</span>
            </Typography>
          </Box>
        </Box>
        <Box theme={theme} className="left-menu">
          <List
            theme={theme}
            options={[
              {
                icon: <Icon name="UserOutlineIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_YOUR_PROFILE,
                onClick: () => {
                  setActiveTab(profileTabs.PROFILE);
                },
                selected: activeTab == profileTabs.PROFILE,
              },
              {
                icon: <Icon name="ListRoundedIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_ORDERS,
                onClick: () => {
                  setActiveTab(profileTabs.ORDERS);
                },
                selected: activeTab == profileTabs.ORDERS,
              },
              {
                icon: <Icon name="ListRoundedIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_APPOINTMENTS,
                onClick: () => {
                  setActiveTab(profileTabs.APPOINTMENTS);
                },
                selected: activeTab == profileTabs.APPOINTMENTS,
              },
              {
                icon: <Icon name="ListRoundedIcon" />,
                label: 'Pre-Qualification',
                onClick: () => {
                  setActiveTab(profileTabs.PRE_QUALIFIED);
                },
                selected: activeTab == profileTabs.PRE_QUALIFIED,
              },
            ]}
          />
        </Box>
      </Box>
      <Box theme={theme} className="right-content">
        {getTab(activeTab)}
      </Box>
    </ProfileLayoutWrap>
  );
};
