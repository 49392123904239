import { useContext, useEffect, useReducer } from 'react'
import { useTheme } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom';
import { Snackbar } from '@ntpkunity/controls'
import { EmailVerificationLinkComponent } from '@ntpkunity/controls-ums'
import { AuthLayoutWrap } from '../layout.style'
import { SnackbarUtility } from 'utilities'
import { Navigation } from 'constants/navigation';
import { AppContext } from '@app/context-provider';

export const EmailVerificationPage = () => {
  const theme = useTheme()
  const { tenant } = useParams()

  const location = useLocation()
  const email = location.state?.email;

  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  const translations = {
    title: PLACEHOLDERS.EMAIL_VERIFICATION_TITLE,
    primaryDescription: PLACEHOLDERS.EMAIL_VERIFICATION_PRIMARY_DESCRIPTION,
    secondaryDescription: PLACEHOLDERS.EMAIL_VERIFICATION_SECONDARY_DESCRIPTION,
    durationText: PLACEHOLDERS.EMAIL_VERIFICATION_DURATION_TEXT,
    helpText: PLACEHOLDERS.EMAIL_VERIFICATION_HELP_TEXT,
    linkText: PLACEHOLDERS.EMAIL_VERIFICATION_LINK_TEST,
    emailSentMessageText: PLACEHOLDERS.EMAIL_SENT_MESSAGE_TEXT
  }

  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      <EmailVerificationLinkComponent
        theme={theme}
        layoutText={translations}
        setResponse={(response) => {
          if (response.status === 200) {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(response.message))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
          } else {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.EMAIL_VERIFICATION_LINK_NOT_SENT))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
          }
        }}
        verificationScreenUrl={Navigation.AUTHENTICATION.USER_PROFILE.replace(':tenant', tenant)}
        email={email}
        slug={tenant}
      />
      <Snackbar
        theme={theme}
        message={snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
    </AuthLayoutWrap>
  )
}
