import { useGetCustomerByEmail, useGetVehicleByVin, useGetCurrencies, useGetDealerProfileByDealerCode, useGetStates, useGetOrderInformation, useWorkFlowNextStep, useGetSetupData, useGetAllPrograms } from "hooks";
import { useParams} from "react-router-dom";
import { useContext, useEffect, useReducer, useState } from 'react'
import { loadDefaultCurrencyConfigurations, useAppContext } from '@app/context-provider'
import {ActionType, useOrderContext} from 'pages'
import { addCustomerReferenceId, getOrder, UpdateisPaymentUpdated} from './order-context-provider'
import { PreOrderJourneyLayoutComponent } from "../../../layouts/journey-layout/pre-order-journey-layout.component";
import { PostOrderJourneyLayoutComponent } from "../../../layouts/journey-layout/post-order-journey-layout.component";
import { CircleLoader, Snackbar } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { Status, PageType } from "../../../constants/enums";
import { JourneyLayoutContextProvider } from "../../../layouts/journey-layout/journey-layout-context-provider";
import {areArraysEqual} from "../../../helpers/methods";
import {FormProvider} from "./form-context-provider";
import { OrderUpdatePopup } from "@libraries";
import { useWorkflowContext } from "@app/router-workflow";
import { user_session, FinanceTypes } from '@constants'
import { useTags } from 'hooks/event-analytics'
import { programData } from 'pages/(order-management)/order/order-context-provider'
import { Event } from 'apis/event-analytics'
import { SnackbarUtility } from "@utilities";

export const OrderPage = () => {
  const theme = useTheme()
  const { vin } = useParams()
  const { orderId } = useParams()
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const { state: {order, activeTab}, dispatch: orderDispatch ,handleUpdateOrder} = useOrderContext()
  const [showPopup, setShowPopup] = useState(false);
  const [orderTabs, setOrderTabs] = useState([]);
  const [nextTab, setNextTab] = useState('');
  const { data: vehicle, isLoading: vehicleLoading } = useGetVehicleByVin(vin, appState?.slug)
  const { getOrderWorkflow } = useWorkflowContext()
  const { data: orderData, isLoading: orderLoading } = useGetOrderInformation(orderId)
  const { data: currencyData } = useGetCurrencies(appState?.slug)
  const { data: customerData } = useGetCustomerByEmail(JSON.parse(localStorage.getItem("settings"))?.email);

  const handlePopupClose =  async() => {
     orderDispatch( UpdateisPaymentUpdated(false));
        handleUpdateOrder();
};

  useGetDealerProfileByDealerCode(vehicle?.dealer_code, appState?.slug);
  useGetStates(appState?.slug)

  useEffect(() => {
    if (orderData) {
      if (orderData?.status == Status.Withdrawn)
        getOrderWorkflow()
      orderDispatch(getOrder(orderData))
    }
  }, [orderData])

  useEffect(() => {
    if (customerData) {
      orderDispatch(addCustomerReferenceId(customerData?.reference_id))
    }
  }, [customerData])

  useEffect(() => {
    const defaultCurrency = currencyData?.find(
      (currency: any) => currency.id === appState.default_currency
    );
    appDispatch(loadDefaultCurrencyConfigurations(defaultCurrency));
  }, [currencyData]);




  const handleLinkClick = () => {
    setShowPopup(true);
  };

  const handleCloseDialog = () => {
    setShowPopup(false);
  };

  const onNextTabClick = () => {
    const currentTabIndex = orderTabs?.findIndex((item) => item == activeTab);
    const nextTab = orderTabs?.length > currentTabIndex + 1 ? orderTabs[currentTabIndex + 1] : ''
    setNextTab(nextTab)
  }

  useEffect(() => {
    const workflow: any = JSON.parse(localStorage.getItem('workflow'))
    if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
        setOrderTabs(workflow?.meta_data?.tabs)
      }
  },[] )

  const commonProps = {
    vehicle,
    showPopup,
    orderTabs,
    nextTab,
    handleLinkClick,
    handleCloseDialog,
    onNextTabClick,
    setNextTab
  };

  return (
    <JourneyLayoutContextProvider>
      {orderLoading ? <CircleLoader theme={theme} size='lg' /> :
        orderData?.status === Status.Approved || orderData?.status === Status.Appointment ?
          <PostOrderJourneyLayoutComponent
            {...commonProps}
            children
          />
          :
          <PreOrderJourneyLayoutComponent
            {...commonProps}
            children
          />
      }

    </JourneyLayoutContextProvider>
  )
}
