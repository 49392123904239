import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ResetPasswordComponent } from '@ntpkunity/controls-ums'
import { StatusCodes } from 'http-status-codes'

import { Navigation } from '../../../constants'
import { AuthLayoutWrap } from '../layout.style'
import { AppContext } from '@app/context-provider'

const expiredLinkTypes = {
  verfication: 'verfication',
  password: 'password'
}

export const ResetPasswordPage = () => {

  const theme = useTheme()
  const { tenant } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('tab')

  const [response, setResponse] = useState<any>({})
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  useEffect(() => {
    if (response?.status === StatusCodes.OK) {
      navigate(`${Navigation.AUTHENTICATION.LOG_IN_PAGE.replace(':tenant', tenant)}`, { replace: true })
    } else if (response?.status === StatusCodes.NOT_FOUND) {
      navigate(`${Navigation.AUTHENTICATION.RESET_PASSWORD_LINK_EXPIRED.replace(':tenant', tenant)}`, {
        replace: true,
        state: {
          token,
          verificationScreenUrl: ``,
          type: expiredLinkTypes.password,
          layoutText: {
            title: PLACEHOLDERS.EXPIRED_LINK_TITLE,
            subTitle: PLACEHOLDERS.RESET_PASSWORD_EXPIRED_RESET_LINK_SUB_TITLE,
            buttonText: PLACEHOLDERS.RESET_PASSWORD_EXPIRED_RESET_LINK_BUTTON_TEXT
          }
        }
      })
    }
  }, [response])

  const translations = {
    title: PLACEHOLDERS.RESET_PASSWORD_TITLE,
    helpText: PLACEHOLDERS.RESET_PASSWORD_HELP_TEXT,
    buttonText: PLACEHOLDERS.RESET_PASSWORD_BUTTON_TEXT,
    passwordHelperText: PLACEHOLDERS.RESET_PASSWORD_PASSWORD_HELPER_TEXT,
    seoButtonText: PLACEHOLDERS.RESET_PASSWORD_SEO_BUTTON_TEXT,
    newPasswordPromptText: PLACEHOLDERS.ENTER_NEW_PASSWORD_TEXT,
    passwordText: PLACEHOLDERS.PASSWORD_TEXT,
    confirmPasswordPromptText: PLACEHOLDERS.CONFIRM_PASSWORD_TEXT,
    passwordMismatchErrorText: PLACEHOLDERS.CONFIRM_PASSWORD_NOT_MATCHING,
    isRequiredText: PLACEHOLDERS.IS_REQUIRED_TEXT
  }

  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      <ResetPasswordComponent
        setResponse={setResponse}
        validateUrl={token as string}
        nextScreenUrl={Navigation.AUTHENTICATION.SIGN_IN_PAGE}
        theme={theme}
        layoutText={translations}
        slug={tenant}
      />
    </AuthLayoutWrap>
  )
}
