import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { useTheme } from '@mui/material'
import { Snackbar } from '@ntpkunity/controls'
import { ResetPasswordLinkComponent } from '@ntpkunity/controls-ums'

import { SnackbarUtility } from 'utilities'
import { Navigation } from '../../../constants'
import { AuthLayoutWrap } from '../layout.style'
import { AppContext } from '@app/context-provider'

export const ResendPasswordLinkPage = () => {
  const theme = useTheme()
  const { tenant } = useParams()
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
  const [response, setResponse] = useState<any>({})

  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  const translations = {
    title: PLACEHOLDERS.RESEND_PASSWORD_TITLE,
    passwordResetEmailText: PLACEHOLDERS.RESEND_PASSWORD_RESET_EMAIL_TEXT,
    updatePasswordLinkText: PLACEHOLDERS.RESEND_PASSWORD_UPDATE_LINK_TEXT,
    resetLinkValidText: PLACEHOLDERS.RESEND_PASSWORD_RESET_LINK_TEXT,
    minutesText: PLACEHOLDERS.MINUTES_TEXT,
    dotText: PLACEHOLDERS.DOT_TEXT,
    resetPasswordEmailNotReceivedText: PLACEHOLDERS.RESEND_PASSWORD_EMAIL_NOT_RECEIVED_TEXT,
    resendText: PLACEHOLDERS.RESEND_PASSWORD_RESEND_TEXT
  }

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')

  if (!tenant) {
   return <div>{PLACEHOLDERS.RESEND_PASSWORD_MISSING_TENANT}</div>
  }

  const reset_password_link = `${Navigation.AUTHENTICATION.RESET_PASSWORD.replace(':tenant', tenant)}`

  useEffect(() => {
    if (response?.status === 200) {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.RESEND_PASSWORD_RESET_LINK_SENT))
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
    } else if (response?.status === 404) {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.RESEND_PASSWORD_RESET_LINK_NOT_SENT))
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
    }
  }, [response])

  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      <ResetPasswordLinkComponent
        setResponse={setResponse}
        email={email}
        theme={theme}
        nextScreenUrl={reset_password_link}
        layoutText={translations}
        slug={tenant}
      />
      <Snackbar
        theme={theme}
        message={snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
    </AuthLayoutWrap>
  )
}
