import { AppContext } from '@app/context-provider';
import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { useContext } from 'react';

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => {
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders
  
  return {
    '&.page-wrap': {
    '.scroll-horizontal': {
      '.selection-button-wrap': {
        overflowX: 'auto',
        overflowY: 'hidden',
        marginLeft: -24,
        marginRight: -24,
        paddingLeft: 24,
        paddingRight: 24,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
          display: 'none',
          scrollbarWidth: 'none'
        }
      }
    },
    '.slider-monthly': {
      '.MuiSlider-valueLabel': {
        '.MuiSlider-valueLabelLabel': {
            '&::after': {
                content: `"${PLACEHOLDERS.PAYMENT_MONTHLY_PAYMENT_FIELD_LABEL}"`,
            }
        }
      }
    },
    '.slider-downpayment': {
      '.MuiSlider-valueLabel': {
        '.MuiSlider-valueLabelLabel': {
            '&::after': {
                content: `"${PLACEHOLDERS.PAYMENT_DOWN_PAYMENT_FIELD_LABEL}"`,
            }
        }
      }
    }
  }
}})
