export const Tabs = {
  PAYMENTS: "Payment",
  INSURANCE: "Insurance",
  FNI: "F&I Products",
  ADD_ONS: "Add Ons",
  TRADE_IN: "Trade In",
  SCHEDULE: "Schedule",
  FINANCING: "Financing",
  REVIEW_ORDER: "Review Order",
  STRIPE_PAYMENTS: "Stripe Payment",
  CONTRACTING: "Contracting",
  SUBMIT_ORDER: "Submit Deal",
  PRICING: "Pricing",
  NOT_AVAILABLE: "Not Available"
};

export const profileTabs = {
  PROFILE: "Profile",
  ORDERS: "Deals",
  FAVORITES: "Favorites",
  APPOINTMENTS: "Appointments",
  PREFERENCES: "Preferences",
  PRE_QUALIFIED: "Pre-Qualified"
}