import { Routes, Route, useNavigate } from "react-router-dom";
import {
  HomePage,
  SignInPage,
  OtpPage,
  EmailVerificationPage,
  UserProfilePage,
  ForgotPasswordPage,
  ResendPasswordLinkPage,
  ResetPasswordPage,
  ResetPasswordLinkExpiredPage,
  SignupConsent,
  OrderPage,
  OrderContextProvider,
  InventoryPage,
  SubmitOrderPage,
  AppointmentsPage,
  FavoritesPage,
  MyOrdersPage,
  PreQualificationPage,
} from "@pages";
import { Navigation } from "@constants";
import ErrorBoundary from "./errorboundry";
import { Configurations } from "./configuration";
import { BaseLayout, ProfileLayout } from "@layouts";
import { WorkflowProvider } from "./router-workflow";
import { ProtectedRoute, Unprotected } from "./router-protection";
import { FormProvider } from "../pages/(order-management)/order/form-context-provider";
import usePageTitle from "../hooks/page-title";
import { useTheme } from "@mui/material";
import { VinSynchronizeWrap } from "@hooks";

export const RouteProvider = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  usePageTitle();

  return (
    <WorkflowProvider>
      <FormProvider>
        <BaseLayout>
          <ErrorBoundary theme={theme} navigate={navigate}>
            <Routes>
              <Route path={Navigation.BASE_PATH} element={<Configurations />}>
                {/* ==================== (Home) ==================== */}
                <Route index element={<HomePage />} />
                {/* ==================== (Home) ==================== */}

                {/* ==================== (Authentication) ==================== */}
                {
                  [
                    Navigation.AUTHENTICATION.SIGN_IN_PAGE,
                    Navigation.AUTHENTICATION.SIGN_UP_PAGE,
                    Navigation.AUTHENTICATION.LOG_IN_PAGE
                  ].map(signInRoute => (
                    <Route
                      key={signInRoute}
                      path={signInRoute}
                      element={<Unprotected Component={SignInPage} />}
                    />
                  ))
                }
                <Route
                  path={Navigation.AUTHENTICATION.OTP_PAGE}
                  element={<Unprotected Component={OtpPage} />}
                />
                <Route
                  path={Navigation.AUTHENTICATION.FORGOT_PASSWORD}
                  element={<Unprotected Component={ForgotPasswordPage} />}
                />
                <Route
                  path={Navigation.AUTHENTICATION.RESEND_PASSWORD_LINK}
                  element={<Unprotected Component={ResendPasswordLinkPage} />}
                />
                <Route
                  path={`/${Navigation.AUTHENTICATION.RESET_PASSWORD}`}
                  element={<Unprotected Component={ResetPasswordPage} />}
                />
                <Route
                  path={Navigation.AUTHENTICATION.EMAIL_VERIFICATION}
                  element={<Unprotected Component={EmailVerificationPage} />}
                />
                <Route
                  path={Navigation.AUTHENTICATION.RESET_PASSWORD_LINK_EXPIRED}
                  element={
                    <Unprotected Component={ResetPasswordLinkExpiredPage} />
                  }
                />
                <Route
                  path={Navigation.AUTHENTICATION.USER_PROFILE}
                  element={<Unprotected Component={UserProfilePage} />}
                />
                <Route
                  path={Navigation.AUTHENTICATION.SIGNUP_CONSENT}
                  element={<Unprotected Component={SignupConsent} />}
                />
                {/* ==================== (Authentication) ==================== */}

                {/* ================= (inventory-management) ================= */}
                <Route
                  path={Navigation.INVENTORY_PAGE}
                  element={<InventoryPage />}
                />
                {/* ================= (inventory-management) ================= */}

                {/* ================= (order-management) ================= */}
                <Route
                  path={Navigation.ORDER_PAGE}
                  element={
                    <VinSynchronizeWrap>
                      <OrderContextProvider>
                        <OrderPage />
                      </OrderContextProvider>
                    </VinSynchronizeWrap>
                  }
                />
                <Route
                  path={Navigation.ORDER_ID_PAGE}
                  element={
                    <VinSynchronizeWrap>
                      <OrderContextProvider>
                        <OrderPage />
                      </OrderContextProvider>
                    </VinSynchronizeWrap>
                  }
                />
                {/* ================= (order-management) ================= */}

                {/* ================= (submit-order) ================= */}
                <Route
                  path={Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE}
                  element={
                    <VinSynchronizeWrap>
                      <OrderContextProvider>
                        <SubmitOrderPage />
                      </OrderContextProvider>
                    </VinSynchronizeWrap>
                  }
                />
                {/* ================= (order-management) ================= */}

                {/* ================= (Profile) ================= */}
                <Route
                  path={Navigation.PROFILE.USER_PROFILE}
                  element={<ProtectedRoute Component={ProfileLayout} />}
                />
                <Route
                  path={Navigation.PROFILE.APPOINTMENTS}
                  element={<ProtectedRoute Component={AppointmentsPage} />}
                />
                <Route
                  path={Navigation.PROFILE.FAVORITES}
                  element={<ProtectedRoute Component={FavoritesPage} />}
                />
                <Route
                  path={Navigation.PROFILE.ORDERS}
                  element={<ProtectedRoute Component={MyOrdersPage} />}
                />
                <Route
                  path={Navigation.PROFILE.PRE_QUALIFIED}
                  element={<ProtectedRoute Component={PreQualificationPage} />}
                />
              </Route>
              <Route
                path={Navigation.PAGE_NOT_FOUND}
                element={<>Page not found.</>}
              />
            </Routes>
          </ErrorBoundary>
        </BaseLayout>
      </FormProvider>
    </WorkflowProvider>
  );
};
