import { styled } from '@mui/material/styles'

export const NavLinkWrap = styled(
  'a',
  {}
)(({ theme }) => ({
  '&.nav-link': {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: 0,
    color: theme.palette.grey[600],
    textDecoration: 'none',
    backgroundColor: 'transparent',
    transition: 'all .3s ease-in-out',
    '.link': {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 8,
      '.start-icon': {
        display: 'flex',
        gap: 12,
      },
      '&:hover': {
        textDecoration: 'none !important'
      }
    },
    '&:hover': {
        color: theme.palette.grey[900],
    },
    '&.active': {
        color: theme.palette.grey[900],
        '.MuiTypography-root': {
        fontWeight: theme.customVariables.fontWeightSemiBold
        },
        '.active-indicator': {
          transform: 'scale(1)',
        },
        [theme.breakpoints.down('md')]: {
          backgroundColor: '#F5F5F7'
        }
    },
    '.active-indicator': {
        display: 'flex',
        padding: '0px 8px',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'stretch', 
        position: 'absolute',
        top: 27,
        width: '100%',
        transition: 'all 0.3s ease-in-out 0s',
        transform: 'scale(0)',
        '&::after': {
          content: '" "',
          display: 'block',
          width: '100%',
          minWidth: 24,
          maxWidth: 48,
          height: 4,
          borderRadius: 2,
          backgroundColor: theme.palette.primary.main,
          margin: 'auto',
          alignSelf: 'stretch',
          [theme.breakpoints.down('md')]: {
            minWidth: 4,
            maxWidth: 4,
            height: 20,
            margin: 0
          }
        },
        [theme.breakpoints.down('md')]: {
          top: 18,
          width: 'auto',
          left: 0,
          padding: 0
        }
    },
    '&.dropdown-toggle': {
        '&::after': {
        display: 'inline-block',
        width: 0,
        height: 0,
        marginLeft: '.255em',
        verticalAlign: '.255em',
        content: "''",
        borderTop: '.3em solid',
        borderRight: '.3em solid transparent',
        borderBottom: 0,
        borderLeft: '.3em solid transparent'
        }
    },
    '.u-avator': {
        backgroundColor: theme.palette.grey[400]
    },
    'svg': {
      'path': {
        stroke: theme.palette.grey[300]
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: '18px 24px',
      borderRadius: theme.shape.borderRadius
    }
  }
}))
